import {
  PRIVACY_POLICY_LINK,
  TERMS_CONDITIONS_LINK,
} from "../constants/camvioLinks";

export const paths = {
  addEquipment: "/equipments/new",
  addField: "/fields/new",
  agronomist: (id) => `/agronomists/${id}`,
  agronomists: "/agronomists",
  editEquipment: (id) => `/equipments/${id}/edit`,
  equipment: (id) => `/equipments/${id}`,
  equipments: "/equipments",
  farmer: (id) => `/farmers/${id}`,
  farmers: "/farmers",
  field: (id) => `/fields/${id}`,
  fields: "/fields",
  forgotPassword: "/auth/forgot-password",
  home: "/",
  login: "/auth/login",
  passwordRecovery: "/password-recovery/:uid/:token",
  privacyPolicy: PRIVACY_POLICY_LINK,
  register: "/auth/register",
  settings: {
    account: "/settings/account",
    index: "/settings",
    machineries: "/settings/machineries",
    notifications: "/settings/notifications",
    profile: "/settings/profile",
  },
  terms: TERMS_CONDITIONS_LINK,
  unauthorized: "/unauthorized-error403",
  dashboardKyd: "/dahboard",
  userActivation: "/users/activate/:uid/:token",
};
