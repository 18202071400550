import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { ACTION_STATUS } from "constants/actionStatus";
import { sortObjectsByProperty } from "utils/sortUtils";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormRadioGroupInput, FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import DetailSection from "../DetailSection";
import DateSpan from "../date-span/DateSpan";
import QuantityInput from "../quantity/QuantityInput";
import useAvailableQuantityUnits from "../../utils/useAvailableOperationUnits";
import { useFilteredFertilizers } from "./useFertilizers";
import Limits from "./Limits";
import EquipmentUsed from "../equipment/EquipmentUsed";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const FertilizePerformed = ({
  viewMode,
  isFullWidth,
  operations,
  fertilizers,
}) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const { values } = useFormikContext();
  const status = ACTION_STATUS.performed;
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  const { availableQuantityUnits } = useAvailableQuantityUnits(
    values.taskId,
    values.fertilizeAction?.operationTypeId
  );

  const { filteredFertilizers, limits, handleFertilizerChange, bioSelections } =
    useFilteredFertilizers({ fertilizers, operations });

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection
          title={t("ActionModal.Fertilizer")}
          isRequired={!isViewMode}
        >
          <div className="action-details-column-multiple">
            <FormRadioGroupInput
              name="fertilizeAction.bio"
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={bioSelections.map(
                ({ value, label: { nameEn, nameGr } }) => ({
                  value,
                  label: isEn ? nameEn : nameGr,
                })
              )}
            />
            <FormSelectInput
              name="fertilizeAction.operationTypeId"
              placeholder={t("ActionModal.FertilizerOperation")}
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              options={operations?.map(
                ({ operationTypeName, operationTypeId }) => ({
                  value: operationTypeId,
                  label: operationTypeName,
                })
              )}
            />
            <FormSelectInput
              name="fertilizeAction.fertId"
              shouldHaveErrorBehaviorAfterSubmit
              isViewMode={isViewMode}
              isDisabled={
                !values.fertilizeAction?.operationTypeId ||
                values.fertilizeAction?.bio === undefined
              }
              onChange={handleFertilizerChange}
              options={filteredFertilizers
                .map(({ ourId, brandName }) => ({
                  value: ourId,
                  label: brandName,
                }))
                .sort((a, b) => sortObjectsByProperty(a, b, "label"))}
            />
          </div>
        </DetailSection>
        <DetailSection
          title={t("ActionModal.Quantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            actionBaseName="fertilizeAction"
            quantityOptions={availableQuantityUnits}
          />
          <Limits limits={limits} />
        </DetailSection>
        <EquipmentUsed isInEditMode={viewMode !== ACTION_VIEW_MODE.view} />
        <ActionModalDescriptionSection
          actionBaseName="fertilizeAction"
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};

export default FertilizePerformed;
