import axiosInstance from "../utils/axiosInstance";
import { URLS } from "../utils/baseUrls";

const getSuggestedActions = ({ fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions-suggested/?field_id=${fieldId}
  `
  );
};

const getCompletedActions = ({ fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions-performed/?field_id=${fieldId}
  `
  );
};

const getActions = ({ fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions/?field_id=${fieldId}&page_size=10000000`
  );
};
const getAction = ({ actionId, fieldId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions/${actionId}/?field_id=${fieldId}`
  );
};

const postSuggestedActions = (postData) => {
  return axiosInstance.post(
    `${URLS.externalRequests}/actions-suggested/?field_id=${postData.fieldId}`,
    postData
  );
};

const postCompletedActions = (postData) => {
  return axiosInstance.post(
    `${URLS.externalRequests}/actions-performed/?field_id=${postData.fieldId}`,
    postData
  );
};

const deleteSuggestedActions = (fieldId, actionId) => {
  return axiosInstance.delete(
    `${URLS.externalRequests}/actions-suggested/${actionId}/?field_id=${fieldId}`
  );
};

const deleteCompletedActions = (fieldId, actionId) => {
  return axiosInstance.delete(
    `${URLS.externalRequests}/actions-performed/${actionId}/?field_id=${fieldId}`
  );
};

const editSuggestedActions = (fieldId, actionId, editData) => {
  return axiosInstance.patch(
    `${URLS.externalRequests}/actions-suggested/${actionId}/?field_id=${fieldId}`,
    editData
  );
};

const editCompletedActions = (fieldId, actionId, editData) => {
  return axiosInstance.patch(
    `${URLS.externalRequests}/actions-performed/${actionId}/?field_id=${fieldId}`,
    editData
  );
};

const transferPerformedActionToSuggested = (fieldId, actionId) => {
  return axiosInstance.post(
    `${URLS.externalRequests}/actions-performed/${actionId}/transfer_to_suggested/?field_id=${fieldId}`,
    null
  );
};

const transferSuggestedActionToPerformed = (fieldId, actionId) => {
  return axiosInstance.post(
    `${URLS.externalRequests}/actions-suggested/${actionId}/transfer_to_performed/?field_id=${fieldId}`,
    null
  );
};

const addComment = ({ fieldId, actionId, text }) => {
  return axiosInstance.post(
    `${URLS.externalRequests}/actions/${actionId}/comments/?field_id=${fieldId}`,
    { text }
  );
};

const deleteComment = ({ fieldId, actionId, commentId }) => {
  return axiosInstance.delete(
    `${URLS.externalRequests}/actions/${actionId}/comments/${commentId}/?field_id=${fieldId}`
  );
};

const getComments = ({ fieldId, actionId }) => {
  return axiosInstance.get(
    `${URLS.externalRequests}/actions/${actionId}/comments/?field_id=${fieldId}`
  );
};

export const actionsApi = {
  comments: {
    fetch: getComments,
    add: addComment,
    delete: deleteComment,
  },
  deleteSuggested: deleteSuggestedActions,
  deletePerformed: deleteCompletedActions,
  fetch: getActions,
  fetchOne: getAction,
  fetchPerformed: getCompletedActions,
  fetchSuggested: getSuggestedActions,
  postPerformed: postCompletedActions,
  postSuggested: postSuggestedActions,
  transferToPerformed: transferSuggestedActionToPerformed,
  transferToSuggested: transferPerformedActionToSuggested,
  updatePerformed: editCompletedActions,
  updateSuggested: editSuggestedActions,
};
