import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { FormDurationInput, FormSelectInput } from "form";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import Title from "../../../Typography/Title";
import DateSpan from "../date-span/DateSpan";
import useAvailableQuantityUnits from "../../utils/useAvailableOperationUnits";
import DetailSection from "../DetailSection";
import QuantityInput from "../quantity/QuantityInput";
import WateringLimits from "./WateringLimits";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const WateringPerformed = ({
  viewMode,
  isFullWidth,
  operationTypeId,
  operations,
}) => {
  const { t } = useTranslation();
  const status = ACTION_STATUS.performed;
  const { values } = useFormikContext();

  const { availableQuantityUnits } = useAvailableQuantityUnits(
    values.taskId,
    operationTypeId
  );
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Performed")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection
          title={t("ActionModal.Operation")}
          isRequired={!isViewMode}
        >
          <FormSelectInput
            name="waterAction.operationTypeId"
            value={operationTypeId}
            shouldHaveErrorBehaviorAfterSubmit
            isDisabled
            isViewMode={isViewMode}
            options={operations?.map(
              ({ operationTypeName, operationTypeId }) => ({
                value: operationTypeId,
                label: operationTypeName,
              })
            )}
          />
          <FormDurationInput
            name="timeTaken"
            style={{ marginTop: "8px", width: "150px" }}
            placeholder={t("ActionModal.Duration.Title") + "*"}
            shouldHaveErrorBehaviorAfterSubmit
            isViewMode={isViewMode}
            units={t("ActionModal.Duration.InMinutes")}
          />
        </DetailSection>
        <DetailSection
          title={t("ActionModal.WaterQuantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            actionBaseName="waterAction"
            quantityOptions={availableQuantityUnits}
          />
          <div style={{ margin: "16px 0px 0px 8px" }}>
            <WateringLimits />
          </div>
        </DetailSection>
        <ActionModalDescriptionSection
          actionBaseName="waterAction"
          isViewMode={isViewMode}
        />
      </div>
    </div>
  );
};

export default WateringPerformed;
