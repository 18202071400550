import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { ACTION_STATUS } from "constants/actionStatus";
import { ACTION_VIEW_MODE } from "constants/actionViewModes";
import { FormSelectInput } from "form";
import Title from "../../../Typography/Title";
import useAvailableQuantityUnits from "../../utils/useAvailableOperationUnits";
import { actionInputName } from "../../utils/inputName";
import DateSpan from "../date-span/DateSpan";
import DetailSection from "../DetailSection";
import WateringLimits from "./WateringLimits";
import QuantityInput from "../quantity/QuantityInput";
import ActionModalDescriptionSection from "../description/ActionModalDescriptionSection";

const WateringSuggestion = ({
  viewMode,
  isFullWidth,
  isProposedValue,
  operationTypeId,
  operations,
}) => {
  const { t } = useTranslation();
  const status = ACTION_STATUS.suggested;
  const { values } = useFormikContext();
  const { availableQuantityUnits } = useAvailableQuantityUnits(
    values.taskId,
    operationTypeId
  );
  const isViewMode = viewMode === ACTION_VIEW_MODE.view;

  return (
    <div className="action-modal-card action-details-box ">
      <div className={isFullWidth && "details-half-width"}>
        <Title title={t("ActionModal.Details.Suggested")} />
        <DateSpan viewMode={viewMode} status={status} />
        <DetailSection
          title={t("ActionModal.Operation")}
          isRequired={!isViewMode}
        >
          <FormSelectInput
            name={actionInputName(
              "waterAction",
              "operationTypeId",
              isProposedValue
            )}
            value={operationTypeId}
            shouldHaveErrorBehaviorAfterSubmit
            isDisabled
            isViewMode={isViewMode}
            options={operations?.map(
              ({ operationTypeName, operationTypeId }) => ({
                value: operationTypeId,
                label: operationTypeName,
              })
            )}
          />
        </DetailSection>
        <DetailSection
          title={t("ActionModal.WaterQuantity")}
          isRequired={!isViewMode}
        >
          <QuantityInput
            isViewMode={isViewMode}
            isProposedValue={isProposedValue}
            actionBaseName="waterAction"
            quantityOptions={availableQuantityUnits}
          />

          <div style={{ margin: "16px 0px 0px 8px" }}>
            <WateringLimits />
          </div>
        </DetailSection>
        <ActionModalDescriptionSection
          actionBaseName="waterAction"
          isViewMode={isViewMode}
          isProposed={isProposedValue}
        />
      </div>
    </div>
  );
};

export default WateringSuggestion;
