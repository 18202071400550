import { ACTION } from "constants/actions";
import moment from "moment";

const constructDateRange = (values) => {
  let dateRange = values.dateRange;
  const isDateRange = Boolean(values.dateRange.lower || values.dateRange.upper);

  if (isDateRange) {
    dateRange = {
      lower: moment(values.datetimePerformed).format("YYYY-MM-DD"),
      upper: moment(values.datetimePerformedEnd).format("YYYY-MM-DD"),
    };
  } else {
    if (values.datetimePerformed && values.datetimePerformedEnd) {
      dateRange = null;
    }
  }

  return dateRange;
};

const constructTimeTaken = (values) => {
  let timeTaken = values.timeTaken || 0;
  if (values?.equipment?.length > 0) {
    timeTaken = values.equipment.reduce((acc, curr) => acc + curr.duration, 0);
  }
  return timeTaken;
};

const removeUnnecessaryFields = (values) => {
  const {
    shouldCheckEnemyId,
    stressType,
    status,
    initialStatus,
    ...finalValues
  } = values;

  return finalValues;
};

const constructWaterPayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

const constructFertilizePayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};
const constructDrugPayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);

  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

const constructDiagnosePayload = (values) => {
  const dateRange = constructDateRange(values);
  const finalCoordinates = values?.diagnoseAction?.sampleCoordinates?.map(
    (coord) => ({ latitude: coord[0], longitude: coord[1] })
  );
  const diagnoseAction = {
    ...values.diagnoseAction,
  };
  if (finalCoordinates) {
    diagnoseAction.sampleCoordinates = finalCoordinates;
  }

  return {
    ...values,
    diagnoseAction,
    dateRange,
  };
};
const constructOtherPayload = (values) => {
  const dateRange = constructDateRange(values);
  const timeTaken = constructTimeTaken(values);
  return {
    ...values,
    dateRange,
    timeTaken,
  };
};

export const constructActionPayload = (type, values) => {
  const reducedValues = removeUnnecessaryFields(values);
  console.log(type);
  switch (type) {
    case ACTION.watering:
      return constructWaterPayload(reducedValues);
    case ACTION.fertilize:
      return constructFertilizePayload(reducedValues);
    case ACTION.drug:
      return constructDrugPayload(reducedValues);
    case ACTION.diagnose:
      return constructDiagnosePayload(reducedValues);
    case ACTION.other:
      return constructOtherPayload(reducedValues);
    default:
      return;
  }
};
