import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { toaster } from "../utils/toaster";
import { useTranslation } from "react-i18next";
import { api } from "api";
import filterByTerm from "utils/filterByTerm";
import { EQUIPMENT_TYPES } from "constants/equipmentTypes";

const EquipmentsContext = createContext({
  equipments: [],
  fetchState: {
    isLoading: false,
    error: undefined,
  },
  createNewEquipment: ({ postData, onSuccess, onError }) => {},
  fetchEquipment: ({ id, onSuccess, onError }) => {},
  updateEquipment: ({ id, patchData, onSuccess, onError }) => {},
  deleteEquipment: ({ id, onSuccess, onError }) => {},
  getFilteredEquipments: ({ searchTerm, type }) => [],
});

const EquipmentsProvider = ({ children }) => {
  const { t } = useTranslation();

  const [equipments, setEquipments] = useState([]);
  const [equipmentsFetchState, setEquipmentsFetchState] = useState({
    isLoading: false,
    error: undefined,
  });

  const fetchAllEquipments = useCallback(
    (handler) => {
      if (t) {
        setEquipmentsFetchState({ isLoading: true, error: undefined });
        api.equipments
          .fetchAll()
          .then((res) => {
            setEquipments(res.data);
            setEquipmentsFetchState({ isLoading: false, error: undefined });
            handler?.onSuccess?.();
          })
          .catch((error) => {
            setEquipmentsFetchState({ isLoading: false, error });
            toaster.error({
              title: t("Equipment.Error.Fetching.Title"),
              message: t("Equipment.Error.Fetching.Message"),
            });
          });
      }
    },
    [t]
  );

  useEffect(() => {
    if (typeof fetchAllEquipments === "function") {
      fetchAllEquipments();
    }
  }, [fetchAllEquipments]);

  const createNewEquipment = useCallback(
    ({ postData, onSuccess, onError }) => {
      api.equipments
        .add(postData)
        .then((res) => {
          toaster.success({ message: t("Equipment.Success.Creating") });
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(res.data),
          });
        })
        .catch((error) => {
          toaster.error({ message: t("Equipment.Error.Creating") });
          onError?.(error);
        });
    },
    [fetchAllEquipments, t]
  );

  const fetchEquipment = useCallback(({ id, onSuccess, onError }) => {
    api.equipments
      .fetchOne(id)
      .then((res) => {
        onSuccess?.(res.data);
      })
      .catch((error) => {
        onError?.(error);
      });
  }, []);

  const updateEquipment = useCallback(
    ({ id, patchData, onSuccess, onError }) => {
      api.equipments
        .update(id, patchData)
        .then((res) => {
          toaster.success({ message: t("Equipment.Success.Updating") });
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(res.data),
          });
        })
        .catch((error) => {
          toaster.error({ message: t("Equipment.Error.Updating") });
          onError?.(error);
        });
    },
    [fetchAllEquipments, t]
  );

  const deleteEquipment = useCallback(
    ({ id, onSuccess, onError }) => {
      api.equipments
        .delete(id)
        .then(() => {
          toaster.success({ message: t("Equipment.Success.Deleting") });
          fetchAllEquipments({
            onSuccess: () => onSuccess?.(),
          });
        })
        .catch((error) => {
          toaster.error({ message: t("Equipment.Error.Deleting") });
          onError?.(error);
        });
    },
    [fetchAllEquipments, t]
  );

  const getFilteredEquipments = useCallback(
    ({ searchTerm, type }) =>
      equipments.filter((equipment) => {
        let flag = filterByTerm.equipment(equipment, searchTerm);
        if (type === EQUIPMENT_TYPES.ALL) return flag;
        return flag && equipment.machineryRead.machineryType === type;
      }),
    [equipments]
  );

  const equipmentsValue = useMemo(
    () => ({
      equipments,
      fetchState: equipmentsFetchState,
      createNewEquipment,
      fetchEquipment,
      updateEquipment,
      deleteEquipment,
      getFilteredEquipments,
    }),
    [
      equipments,
      equipmentsFetchState,
      createNewEquipment,
      fetchEquipment,
      deleteEquipment,
      updateEquipment,
      getFilteredEquipments,
    ]
  );

  return (
    <EquipmentsContext.Provider value={equipmentsValue}>
      {children}
    </EquipmentsContext.Provider>
  );
};

const useEquipmentsContext = () => {
  const context = useContext(EquipmentsContext);

  if (!context) {
    throw new Error(
      "useEquipmentsContext must be used within a EquipmentsProvider"
    );
  }
  return useContext(EquipmentsContext);
};
export { EquipmentsProvider, useEquipmentsContext };
