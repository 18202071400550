import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { paths } from "../utils/paths";
import { storage } from "../utils/localStorage";
import { api } from "../api";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({
  message: {
    text: "",
    isOpen: false,
    type: "",
  },
  setMessage: (text = "", isOpen = false, type = "error") => {},
  isLoading: false,
  login:
    /**
     * Login
     * @param {Object} data
     * @param {string} data.email
     * @param {string} data.password
     */
    (data) => {},
  logout: () => {},
  activateAccount:
    /**
     * Activate account
     * @param {Object} data
     * @param {Object} data.payload
     * @param {string} data.payload.uid
     * @param {string} data.payload.token
     * @param {Function} data.onError
     */
    (data) => {},
  verifyEmail:
    /**
     * Verify Email
     * @param {Object} data
     * @param {string} data.email
     * @param {Function} data.onSuccess
     */
    (data) => {},
  register: {},
});

const AuthProvider = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [message, setMessage] = useState({
    text: "",
    isOpen: false,
    type: "",
  });
  const [isLoading, setIsLoading] = useState();

  const login = useCallback(
    async ({ email, password }) => {
      setIsLoading(true);
      try {
        setMessage(null);
        const payload = {
          email,
          password,
        };
        let res = await api.auth.login(payload);
        storage.setCredsInLocalStorage(
          res?.data?.access,
          res?.data?.refresh,
          res?.data?.roles
        );
        setIsLoading(false);

        window.location.href = paths.home;
      } catch (error) {
        if (error?.response?.status === 401) {
          setMessage({
            text: t("Register.Login.LoginError.Password"),
            isOpen: true,
            type: "error",
          });
        } else {
          setMessage({
            text: t("Register.Login.LoginError.Uknown"),
            isOpen: true,
            type: "error",
          });
        }
        setIsLoading(false);
      }
    },
    [t]
  );

  const logout = useCallback(async () => {
    setIsLoading(true);
    try {
      await api.auth.logout();
      storage.removeCredsFromLocalStorage();
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const register = useCallback(
    async ({ role, data }) => {
      setIsLoading(true);
      try {
        let res = await api.auth.register({ user: data, role });
        return res;
      } catch (error) {
        let message = "";
        if (error?.response?.data?.detail?.user?.email) {
          message = t("Register.RegisterErrorEmailAlreadyExists");
        } else {
          message = t("Register.RegisterError");
        }
        throw message;
      } finally {
        setIsLoading(false);
      }
    },
    [t]
  );

  const activateAccount = useCallback(
    async ({ payload, onError }) => {
      try {
        setMessage();
        let res = await api.account.activate(payload);
        if (res?.status === 200) {
          navigate(paths.login);
        }
      } catch (error) {
        setMessage({
          text: t("Register.AccountActivation.Error"),
          type: "error",
          isOpen: true,
        });
        onError?.();
      }
    },
    [navigate, t]
  );

  const verifyEmail = useCallback(
    async ({ email, onSuccess }) => {
      try {
        setMessage();
        const payload = {
          email,
        };
        let res = await api.account.emailVerification(payload);
        if (res?.status === 200) {
          onSuccess?.();
        }
      } catch (error) {
        console.error("emailVerification error: ", error);
        setMessage({
          text: t("Register.ResendEmailError"),
          type: "error",
          isOpen: true,
        });
      }
    },
    [t]
  );

  let value = useMemo(
    () => ({
      message,
      setMessage,
      isLoading,
      login,
      logout,
      register,
      activateAccount,
      verifyEmail,
    }),
    [message, isLoading, login, logout, register, activateAccount, verifyEmail]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuthContext must be used within a AuthProvider");
  }
  return useContext(AuthContext);
};

export { AuthProvider, useAuthContext };
