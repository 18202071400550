import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { elGR } from "@mui/x-data-grid/locales";
import { useTranslation } from "react-i18next";

// const mockColumns = (t) => {
//   return [
//     // { field: "id", headerName: "ID", width: 70 },
//     { field: "firstName", headerName: t("KydTable.FirstName"), width: 130 },
//     { field: "lastName", headerName: t("KydTable.LastName"), width: 170 },
//     { field: "afm", headerName: t("KydTable.AFM"), width: 170 },
//   ];
// };

// const mockRows = [
//   {
//     id: 1,
//     lastName: "Παπαδόπουλος",
//     firstName: "Μιχάλης",
//     afm: "123456789",
//     camvioConnection: "Αναμένεται",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αποτυχία",
//   },
//   {
//     id: 2,
//     lastName: "Νικολάου",
//     firstName: "Σάρα",
//     afm: "234567891",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αναμένεται",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 3,
//     lastName: "Πετρίδης",
//     firstName: "Λαουρα",
//     afm: "345678912",
//     camvioConnection: "Αποτυχία",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 4,
//     lastName: "Αντωνίου",
//     firstName: "Ιωάννης",
//     afm: "456789123",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αποτυχία",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 5,
//     lastName: "Γεωργίου",
//     firstName: "Αιμιλία",
//     afm: "567891234",
//     camvioConnection: "Αναμένεται",
//     otp: "Αναμένεται",
//     opekepe: "Αποτυχία",
//   },
//   {
//     id: 6,
//     lastName: "Παπακωνσταντίνου",
//     firstName: "Βασίλης",
//     afm: "678912345",
//     camvioConnection: "Αποτυχία",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 7,
//     lastName: "Κωνσταντίνου",
//     firstName: "Αναστασία",
//     afm: "789123456",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αναμένεται",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 8,
//     lastName: "Χριστοδούλου",
//     firstName: "Δημήτριος",
//     afm: "891234567",
//     camvioConnection: "Αναμένεται",
//     otp: "Αποτυχία",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 9,
//     lastName: "Παναγιωτίδης",
//     firstName: "Σοφία",
//     afm: "912345678",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αποτυχία",
//   },
//   {
//     id: 10,
//     lastName: "Διαμαντίδης",
//     firstName: "Αλέξανδρος",
//     afm: "123987654",
//     camvioConnection: "Αναμένεται",
//     otp: "Αποτυχία",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 11,
//     lastName: "Παπαϊωάννου",
//     firstName: "Μαρία",
//     afm: "987654321",
//     camvioConnection: "Αποτυχία",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 12,
//     lastName: "Σωτηρόπουλος",
//     firstName: "Ευάγγελος",
//     afm: "876543219",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αναμένεται",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 13,
//     lastName: "Αθανασίου",
//     firstName: "Γεώργιος",
//     afm: "765432198",
//     camvioConnection: "Αναμένεται",
//     otp: "Αποτυχία",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 14,
//     lastName: "Λαμπρόπουλος",
//     firstName: "Χριστίνα",
//     afm: "654321987",
//     camvioConnection: "Αναμένεται",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αποτυχία",
//   },
//   {
//     id: 15,
//     lastName: "Παπαγεωργίου",
//     firstName: "Ειρήνη",
//     afm: "543219876",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αποτυχία",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 16,
//     lastName: "Παντελής",
//     firstName: "Νικόλαος",
//     afm: "432198765",
//     camvioConnection: "Αποτυχία",
//     otp: "Ολοκλήρωση",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 17,
//     lastName: "Δημητρίου",
//     firstName: "Θεοδώρα",
//     afm: "321987654",
//     camvioConnection: "Αναμένεται",
//     otp: "Αναμένεται",
//     opekepe: "Αποτυχία",
//   },
//   {
//     id: 18,
//     lastName: "Οικονόμου",
//     firstName: "Μάρκος",
//     afm: "219876543",
//     camvioConnection: "Ολοκλήρωση",
//     otp: "Αποτυχία",
//     opekepe: "Ολοκλήρωση",
//   },
//   {
//     id: 19,
//     lastName: "Σταυρόπουλος",
//     firstName: "Άννα",
//     afm: "198765432",
//     camvioConnection: "Αναμένεται",
//     otp: "Ολοκλήρωση",
//     opekepe: "Αναμένεται",
//   },
//   {
//     id: 20,
//     lastName: "Κυριακίδης",
//     firstName: "Ηλίας",
//     afm: "876543210",
//     camvioConnection: "Αποτυχία",
//     otp: "Αναμένεται",
//     opekepe: "Αποτυχία",
//   },
// ];

export default function DataTableCustom({
  columns = [],
  rows = [],
  initialState = {},
  style = {},
  isLoading,
  width = "100%",
  height = "100%",
}) {
  const { i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  return (
    <div style={{ width, height }}>
      <DataGrid
        localeText={
          isEn ? {} : elGR.components.MuiDataGrid.defaultProps.localeText
        }
        loading={isLoading}
        rows={rows}
        columns={columns}
        initialState={initialState}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        checkboxSelection
      />
    </div>
  );
}
