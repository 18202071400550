import { useTranslation } from "react-i18next";
import { EQUIPMENT_TYPES } from "../../../../constants/equipmentTypes";
import SmallText from "../../../Typography/SmallText";
import NormalText from "../../../Typography/NormalText";

const EquipmentItem = ({ equipment }) => {
  const { t, i18n } = useTranslation();
  const isEn = i18n.language.startsWith("en");

  const equipmentType =
    equipment.machineryRead.machineryType === EQUIPMENT_TYPES.MACHINE
      ? t("Equipment.Machine")
      : t("Equipment.Tool");
  const equipmentName = isEn
    ? equipment.machineryRead.nameEn
    : equipment.machineryRead.nameGr;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "start" }}
    >
      <SmallText text={equipmentType} style={{ fontWeight: 300 }} />
      <NormalText text={equipmentName} />
    </div>
  );
};

export default EquipmentItem;
